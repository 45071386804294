<template>
   <div class="form-confirm">
     <v-form ref="form">
        <v-card>
       <v-card-title>{{$t('users.password')}}</v-card-title>
        <v-text-field
          v-model="password"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
          outlined
          :rules="passwordRules"
          dense
          style="width:90%; margin: 0 auto"
        >
        </v-text-field>
        <v-card-title>{{$t('users.confirmPassword')}}</v-card-title>
        <v-text-field
          v-model="confirmPass"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          @click:append="show1 = !show1"
          outlined
          :rules="confirmPasswordRules"
          dense
          style="width:90%; margin: 0 auto"
        >
        </v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
           <div class="d-flex justify-end mt-4 btn-send">
            <v-btn class="btn--primary mr-4" @click="sendPass()">
              {{ $t("buttons.save") }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
     </v-form>
   </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
export default {

  name: 'UserList',
  data () {
    return {
      show: false,
      show1: false,
      password: '',
      confirmPass: '',
      passwordRules: [v => !!v || this.$t('rules.isRequired')],
      confirmPasswordRules: [
        v => !!v || this.$t('rules.isRequired'),
        v => v === this.password || this.$t('rules.confirmMail')]
    }
  },
  methods: {
    ...mapMutations({ setConfirmEmail: 'setConfirmEmail' }),
    ...mapActions({ actionConfrim: 'confirmEmailStaff' }),
    sendPass () {
      if (this.$refs.form.validate()) {
        const confirmEmail = {
          token: this.$route.query.token,
          password: this.password
        }
        this.setConfirmEmail({ confirmEmail: confirmEmail })
        this.actionConfrim()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form-confirm {
  width: 50%;
  margin: auto;
  margin-top: 10vh;
}
::v-deep {
  .v-btn--is-elevated {
  width:100px !important
}
}
</style>
